export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
const USE_PROD_IN_DEV = true;

// eslint-disable-next-line no-nested-ternary
export const API_BASE_URL = USE_PROD_IN_DEV
  ? 'https://expenses-api.qoopit.io'
  : IS_PRODUCTION
  ? 'https://expenses-api.qoopit.io'
  : 'http://localhost:13232';

export const IS_USING_PROD_IN_DEV = !IS_PRODUCTION && USE_PROD_IN_DEV;

export const GOOGLE_CLIENT_ID =
  '908232181294-35o3m48pdponpkmnjiebec0kn6pl4dcr.apps.googleusercontent.com';
