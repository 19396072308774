import React, {useEffect, useState} from 'react';
/* eslint-disable react/jsx-props-no-spreading */
import {faRotate} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from 'components/Datepicker';
import Button from 'components/UI/Button';
import Select from 'components/UI/Select';
import dayjs from 'dayjs';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import movementsService from 'services/movements';
import {useLocalStorage} from 'usehooks-ts';

type Props = {
  defaultValues: CreateMovement | Movement;
  onSaving: () => void;
  onSave: (addAnother: boolean) => void;
  onError: () => void;
  categoriesItems: SelectItem<number>[];
};

function MovementForm({
  defaultValues,
  onSaving,
  onError,
  onSave,
  categoriesItems,
}: Props) {
  const [addAnother, setAddAnother] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>();
  const [lastDate, setLastDate] = useLocalStorage<Date>(
    'lastDate',
    dayjs().startOf('day').toDate()
  );
  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: {isValid},
  } = useForm<CreateMovement | Movement>({
    defaultValues: {
      ...defaultValues,
      date: lastDate || (dayjs().startOf('day').toDate() as Date),
    },
  });

  const watchDate = watch('date');

  useEffect(() => {
    if (watchDate) {
      setLastDate(watchDate);
    }
  }, [watchDate]);

  const storeMovement = async (persistMovement: CreateMovement | Movement) => {
    onSaving();
    setSaving(true);
    const rsp = await movementsService.store(persistMovement);
    if (rsp.status) {
      onSave(addAnother);
    } else {
      onError();
    }
    setSaving(false);
  };

  const onSubmit: SubmitHandler<CreateMovement | Movement> = storeMovement;

  const isEdit = 'id' in defaultValues && defaultValues.id;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col gap-2'>
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-2'>
          <div>
            <input
              tabIndex={-1}
              autoComplete='off'
              className='border border-zinc-200 p-2 rounded w-full text-sm bg-plain'
              placeholder='What...'
              {...register('concept', {required: true})}
            />
          </div>
          <div>
            <Controller
              name='category_id'
              control={control}
              rules={{required: true, validate: (value) => value !== 0}}
              render={({field}) => (
                <div>
                  <Select<SelectItem<number>>
                    items={categoriesItems}
                    placeholderRenderer={
                      <div className='p-2 text-sm'>Select Category...</div>
                    }
                    value={
                      categoriesItems.find((x) => x.value === field.value) ||
                      null
                    }
                    onChange={(v) => {
                      field.onChange(v.value);
                    }}
                  />
                </div>
              )}
            />
          </div>

          <div>
            <input
              autoComplete='off'
              className='border border-zinc-200 p-2 rounded w-full text-sm bg-plain'
              placeholder='How much...'
              {...register('amount', {
                required: true,
                validate: (value) => value !== 0,
              })}
            />
          </div>
        </div>
        <div>
          <Controller
            name='date'
            control={control}
            rules={{required: true}}
            render={({field}) => (
              <>
                <DatePicker
                  defaultValue={field.value}
                  placeholder='When...'
                  {...field}
                />
                <div className='text-xs flex gap-2 mt-4'>
                  <Button
                    as='link'
                    size='sm'
                    onClick={() =>
                      setValue(
                        'date',
                        dayjs().subtract(0, 'day').startOf('day').toDate(),
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                          shouldTouch: true,
                        }
                      )
                    }
                  >
                    Today
                  </Button>
                  <Button
                    as='link'
                    size='sm'
                    onClick={() =>
                      setValue(
                        'date',
                        dayjs().subtract(1, 'day').startOf('day').toDate(),
                        {
                          shouldValidate: true,
                          shouldTouch: true,
                        }
                      )
                    }
                  >
                    Yesterday
                  </Button>
                </div>
              </>
            )}
          />
        </div>

        <div>
          <div
            className={`grid grid-cols-1 ${
              !isEdit ? 'sm:grid-cols-2' : ''
            } gap-4`}
          >
            <Button
              buttonType='submit'
              onClick={() => {
                setAddAnother(false);
              }}
              disabled={!isValid}
            >
              {saving ? (
                <div className='text-center'>
                  <FontAwesomeIcon icon={faRotate} spin />
                </div>
              ) : (
                'Save'
              )}
            </Button>
            {!isEdit && (
              <Button
                buttonType='submit'
                onClick={() => {
                  setAddAnother(true);
                }}
                disabled={!isValid}
              >
                {saving ? (
                  <div className='text-center'>
                    <FontAwesomeIcon icon={faRotate} spin />
                  </div>
                ) : (
                  'Save and Add another'
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default MovementForm;
